import React, { useEffect, createRef } from 'react';
import lottie from 'lottie-web'

import loading from '../../animations/loading.json'
import main from '../../animations/main.json'

export default function Animation ( props ) {

	const { type, repeat, animationComplete, setAnimationComplete } = props

	const animationContainer = createRef()

	const getAnimation = ( type ) => {

		switch ( type ) {
			case 'loading':
				return loading
			case 'main':
				return main
			default:
				return null
		}
	}

	useEffect(() => {

		const anim = lottie.loadAnimation({
			container: animationContainer.current,
			renderer: "svg",
			loop: repeat ? repeat : false,
			autoplay: !animationComplete,
			animationData: getAnimation( type ),
		});

		if ( !repeat ) {
			anim.addEventListener( 'complete', () => {
				setAnimationComplete( true )
	
				setTimeout(() => {
					anim.goToAndStop(0,0)
				}, 3000 )
			} )
		}

		return () => anim.destroy(); // optional clean up for unmounting
	}, [ setAnimationComplete, type ]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="animation" ref={ animationContainer } />
	);
};
