import React from 'react'

import Anchor from '../Anchor/Anchor'
import ClientsList from './ClientsList'

export default function Clients () {

	return (
		<section className="clients">
			<Anchor anchorId="clients" />

			<div className="clients__container">
				<div className="clients__wrapper wrapper">
					<div className="clients__header">
						<h2 className="clients__heading">Clients</h2>
						<h3 className="clients__sub-heading">Our Client Portfolio</h3>
					</div>
				</div>

				<div className="wrapper">
					<ClientsList />
				</div>
			</div>
		</section>
	)
}