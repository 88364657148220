import React from 'react'
import Img from 'gatsby-image'

export default function CreationListItem ( props ) {

	const { data, index, total } = props

	return (
		<div className={`creation-list__item ${ index % 2 ? 'creation-list__item--alt' : '' } ${ index === total - 1 ? 'creation-list__item--no-border' : '' } `}>
			<div className="creation-list__image-container">
				<Img fluid={{ ...data.image.childImageSharp.fluid }} className={`creation-list__image creation-list__image--${ data.device }`} alt={ `${ data.title } website example on a ${ data.device }` } />
			</div>
			<div className="creation-list__content">
				<h3 className="creation-list__heading">{ data.title }</h3>
				<p className="creation-list__description">{ data.description }</p>
				<a href={ data.url } className="creation-list__link" target="_blank" rel="noreferrer">view website</a>
			</div>
		</div>
	)
}