import React from 'react'

import Anchor from '../Anchor/Anchor'

import BackgroundAsset from '../../images/svgs/testimonial-background.svg'

export default function AboutUs () {

	return (
		<section className="about-us">
			<Anchor anchorId="about" />
			<div className="about-us__wrapper wrapper">
				<h1 className="about-us__section-title">About Us</h1>
				<div className="about-us__content-container">
					<div className="about-us__highlight">At CDO Digital, we pride ourselves on working closely with clients, and bringing their visions to life.</div>
					<div className="about-us__text">We collaborate with both clients and designers to create personalised and bespoke websites which function seamlessly across multiple devices, while optimising speed, user experience, and SEO, without the limitations of templates and over-crowded admin panels.</div>
				</div>
			</div>
			<BackgroundAsset className="about-us__background" />
		</section>
	)
}