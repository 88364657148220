import React from 'react'

import Anchor from '../Anchor/Anchor'
import CreationsList from './CreationsList'

export default function Creations () {

	return (
		<section className="creations">
			<Anchor anchorId="creations" />
			<div className="creations__container">
				<div className="creations__wrapper wrapper">
					<div className="creations__header">
						<h2 className="creations__heading">Our latest creations</h2>
						<h3 className="creations__sub-heading">Recent Work</h3>
					</div>
				</div>

				<CreationsList />
			</div>
		</section>
	)
}