import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ContentSlider from '../ContentSlider/ContentSlider'
import TestimonialCard from './TestimonialCard'

import BackgroundAsset from '../../images/svgs/testimonial-background.svg'

export default function Testimonials () {

	const queryData = useStaticQuery(graphql`
		query TestimonialsQuery {
			allTestimonialsJson {
				nodes {
					quote
					author
					company
				}
			}
		}
	`)

	const data = queryData.allTestimonialsJson.nodes

	return (
		<section className="testimonials">
			<div className="testimonials__wrapper wrapper">
				<h2 className="testimonials__section-title">What they said</h2>

				<ContentSlider>
					{ data.map( ( testimonial , i ) => (
						<React.Fragment key={ i }>
							<TestimonialCard data={ testimonial } />
						</React.Fragment>
					) ) }
				</ContentSlider>
			</div>

			<BackgroundAsset className="testimonials__background" />
		</section>
	)
}