import React from 'react'

export default function SubFooter () {

	return (
		<section className="sub-footer">
			<div className="sub-footer__wrapper">
				<h2 className="sub-footer__heading">Let's work together on our next project</h2>

				<ul className="sub-footer__list">
					<li className="sub-footer__list-item">
						<a href="https://www.linkedin.com/company/cdo-digital/" className="sub-footer__link" target="_blank" rel="noreferrer">LinkedIn</a>
					</li>
					<li className="sub-footer__list-item">
						<a href="mailto:info@cdodigital.com" className="sub-footer__link">Email</a>
					</li>
				</ul>
			</div>
		</section>
	)
}