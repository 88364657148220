import React, { useState, useRef } from 'react'

import Animation from '../Animation/Animation'

import BackgroundAsset from '../../images/svgs/contact-us-background.svg'

const EMAIL_KEY = process.env.EMAIL_KEY

export default function ContactForm () {

	const form = useRef();

	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ company, setCompany ] = useState('')
	const [ tel, setTel ] = useState('')
	const [ messageDetails, setMessageDetails ] = useState('')
	const [ formSuccess, setFormSuccess ] = useState(false)
	const [ error, setError ] = useState()
	const [ loading, setLoading ] = useState()

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		setLoading( true )

		const data = {
			name,
			email,
			company,
			tel,
			messageDetails,
			EMAIL_KEY
		}

		fetch( '/api/contact', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify( data ),
		})
		.then( response => response.json() )
		.then( () => {
			setFormSuccess( true )
			setLoading( false )
		} )
		.catch( error => {
			console.log( 'Error:', error )
			setError( true )
			setLoading( false )
		} )
	}

	return (
		<div className="contact-form">
			<form className="form" onSubmit={ handleSubmit } ref={ form }>
				<div className="form__inputs-container-split" >
					<div className="form__input-container">
						<label className="form__label" htmlFor="name">Your name*</label>
						<input
							onChange={ e => { setName(e.target.value) }}
							value={ name }
							className="form__input" id="name" type="text" placeholder="Your full name" required />
					</div>

					<div className="form__input-container">
						<label className="form__label" htmlFor="company">Company name*</label>
						<input
							onChange={ e => { setCompany(e.target.value) }}
							value={ company }
							className="form__input" id="company" type="text" placeholder="Company name" required />
					</div>
				</div>

				<div className="form__inputs-container-split" >
					<div className="form__input-container">
						<label className="form__label" htmlFor="email">Your email*</label>
						<input
							onChange={ e => { setEmail(e.target.value) }}
							value={ email }
							className="form__input" id="email" type="email" placeholder="Your email address" required />
					</div>

					<div className="form__input-container">
						<label className="form__label" htmlFor="phone">Your phone number</label>
						<input
							onChange={ e => { setTel(e.target.value) }}
							value={ tel }
							className="form__input" id="phone" type="tel" placeholder="Your phone number" />
					</div>
				</div>

				<div className="form__input-container">
					<label className="form__label" htmlFor="message">Message</label>
					<textarea
						onChange={ e => { setMessageDetails(e.target.value) }}
						value={ messageDetails}
						className="form__input" id="message" type="text" placeholder="Type your message..." rows="6" required />
				</div>

				<input type="hidden" id="" name="" value=""></input>

				<div className="form__extra-info-container">
					<span className="form__extra-info">Tell us about your vision:</span>
					<span className="form__extra-info">Which challenges are you facing? What are your goals and expectations? What would success look like and how much are you planning to spend to get there?</span>
				</div>

				<button className="form__submit" type="submit">Send message</button>

				{ error &&
					<div className="form__message-container">
						<span className="form__message">Error: We have been unable to send your message. Please try again later.</span>
					</div>
				}

				{ loading &&
					<div className="form__message-container">
						<Animation type="loading" repeat={ true } />
					</div>
				}

				{ formSuccess &&
					<div className="form__message-container">
						<p className="form__message form__message--success">
							Thank you for getting in contact. <span>We will get in touch as soon as possible!</span>
						</p>
					</div>
				}
			</form>

			<BackgroundAsset className="contact-us__background" />
		</div>
	)
}