import React from 'react'

import HoardersLogo from '../../images/svgs/hoarders-logo.svg'
import DreamOnLogo from '../../images/svgs/dream-on-logo.svg'
import AlitusLogo from '../../images/svgs/alitus-logo.svg'
import BlossomLogo from '../../images/svgs/blossom-logo.svg'
import ArcipluviaLogo from '../../images/svgs/arcipluvia-logo.svg'
import SculturaLogo from '../../images/svgs/scultura-logo.svg'

import EyeSculptureTrailLogo from '../../images/eye-sculpture-trail-logo.png'
import WattLogo from '../../images/watt-logo.png'

import PlusIcon from '../../images/svgs/plus-icon.svg'

export default function ClientsListItem ( props ) {

	const { data } = props

	const renderLogo = client => {

		switch ( client ) {
			case 'hoarders':
				return <HoardersLogo className="clients__logo" />
			case 'dream-on':
				return <DreamOnLogo className="clients__logo clients__logo--dream-on" />
			case 'alitus':
				return <AlitusLogo className="clients__logo clients__logo--alitus" />
			case 'blossom':
				return <BlossomLogo className="clients__logo clients__logo--blossom" />
			case 'arcipluvia':
				return <ArcipluviaLogo className="clients__logo clients__logo--arcipluvia" />
			case 'scultura':
				return <SculturaLogo className="clients__logo clients__logo--scultura" />
			case 'watt':
				return <img src={ WattLogo } className="clients__logo clients__logo--watt" alt="We Are The Trend Logo" />
			case 'sculpture-trail':
				return <img src={ EyeSculptureTrailLogo } className="clients__logo clients__logo--sculpture-trail" alt="Eye Sculpture Trail Logo" />
			default:
				return null
		}
	}

	const addModifier = client => {

		switch ( client ) {
			case 'sculpture-trail':
				return true
			case 'watt':
				return true
			default:
				return false
		}
	}

	return (
		<a className={`clients__client ${ addModifier( data.logo ) ? 'clients__client--alt' : '' }`} href={ data.url } target="_blank" rel="noreferrer" aria-label={ `${ data.logo } website` }>

			{ renderLogo( data.logo ) }

			<div className="clients__visit-container">
				<div className="clients__visit-text">Visit website</div>
			</div>

			<div className="clients__toggle">
				<PlusIcon className="clients__toggle-icon" />
			</div>
		</a>
	)
}