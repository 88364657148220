import React, { useEffect, useContext } from 'react'

import Animation from '../Animation/Animation'

import useWindowSize from '../../hooks/useWindowSize'

import { AnimationContext } from '../../context/AnimationContext'

export default function HomeHero ( props ) {

	const { animationComplete, setAnimationComplete } = props
	const { animationEnabled } = useContext( AnimationContext )
	const { height } = useWindowSize()

	useEffect(() => {
		const doc = document.documentElement
		doc.style.setProperty('--app-height', `${height}px`)

		if ( !animationEnabled ) {
			setAnimationComplete( true )
		}
	}, [ animationEnabled, height, setAnimationComplete ])

	return (
		<div className={`home-hero ${animationComplete ? 'animation-complete' : ''}`}>

			<div className="home-hero__wrapper">
				<Animation type="main" animationComplete={ animationComplete } setAnimationComplete={ setAnimationComplete } />
			</div>
		</div>
	)
}