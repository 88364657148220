import React, { useState, useRef, useEffect } from 'react'

import ContentSlide from './ContentSlide'

import Chevron from '../../images/svgs/chevron-icon.svg'

export default function ContentSlider( props ) {

	const RESIZE_DEBOUNCE = 150;

	// Uses the reference from the ContentSlide
	const contentSlideRef = useRef()

	const { children, enableArrows } = props

	const [ slideWidth, setSlideWidth ] = useState()
	const [ activeSlide, setActiveSlide ] = useState(0)
	const [ slidePos, setSlidePos ] = useState(0)

	const itemsPerPage = 1

	useEffect( () => {

		if ( contentSlideRef ) {
			setSlideWidth( contentSlideRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setSlideWidth( contentSlideRef.current.offsetWidth ), RESIZE_DEBOUNCE );
			// on resize reset slider
			setActiveSlide( 0 )
			setSlidePos( 0 )
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};

	}, [] );

	const toggleSlide = index => {
		setActiveSlide( index )
		setSlidePos( slideWidth * index );
	}

	/**
	 * Checks if the slide is in the current view, if so return true
	 * @param {Number} slideIndex
	 */
	const isSlideActive = slideIndex => {
		let isActive = false;

		if ( slideIndex < activeSlide + itemsPerPage && slideIndex >= activeSlide ) {
			isActive = true;
		}

		return isActive;
	}

	return (
		<div className={`content-slider ${ enableArrows ? 'content-slider--alt' : '' }`}>
			<div className="content-slider__container">
				<div className="content-slider__wrapper">
					<ul
						className="content-slider__inner-wrapper block-list-1"
						style={{ left: `-${ slidePos }px` }}>
						{ children.map( ( child, index ) => (
							<ContentSlide
								content={ child.props }
								ref={ contentSlideRef }
								key={ index }
								isActiveSlide={ isSlideActive( index ) } />
						) ) }
					</ul>
				</div>
			</div>

			<div className="content-slider__navigation">
				{ children.map( ( item, index ) => (
					<button
						className="content-slider__navigation-item"
						onClick={ () => toggleSlide( index ) }
						aria-label={ `Testimonial ${ index + 1 }` }
						key={ index }>
						<span className={`content-slider__navigation-bar ${ isSlideActive( index ) ? 'active' : '' }`} />
					</button>
				) ) }
			</div>

			{ enableArrows &&
				<>
					<Chevron
						className={`content-slider__control content-slider__control--prev ${ !activeSlide ? 'disabled' : '' }`}
						onClick={ () => toggleSlide( activeSlide - 1 ) } />
					<Chevron
						className={`content-slider__control content-slider__control--next ${ activeSlide + 1 === children.length ? 'disabled' : '' }`}
						onClick={ () => toggleSlide( activeSlide + 1 ) } />
				</>
			}
		</div>
	)
}