import React from 'react'

import Anchor from '../Anchor/Anchor'
import ServiceCardGrid from '../ServiceCard/ServiceCardGrid'

import BackgroundAsset from '../../images/svgs/services-background.svg'

export default function Services () {

	return (
		<section className="services">
			<Anchor anchorId="services" />
			<div className="services__wrapper wrapper">
				<div className="services__header">
					<h2 className="services__heading">Our services</h2>
					<h3 className="services__sub-heading">What we offer at CDO Digital</h3>
					<p className="services__extra-info">Using our extensive web-development experience, we can provide:</p>
				</div>

				<ServiceCardGrid />
			</div>
			<BackgroundAsset className="services__background" />
		</section>
	)
}