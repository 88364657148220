import React from 'react'

import Anchor from '../Anchor/Anchor'
import ContactForm from './ContactForm'

export default function ContactUs () {

	return (
		<section className="contact-us">
			<Anchor anchorId="contact-us" />
			<div className="contact-us__wrapper wrapper">
				<div className="contact-us__header">
					<h2 className="contact-us__heading">Contact</h2>
					<h3 className="contact-us__sub-heading">Get in contact</h3>
				</div>

				<ContactForm />
			</div>
		</section>
	)
}