import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ContentSlider from '../ContentSlider/ContentSlider'
import CreationListItem from './CreationListItem'

import CDOLogo from '../../images/svgs/secondary-logo.svg'

export default function CreationsList () {

	const queryData = useStaticQuery(graphql`
		query CreationsQuery {
			allCreationsJson {
				nodes {
					title
					description
					url
					device
					image {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	`)

	const data = queryData.allCreationsJson.nodes

	return (
		<div className="creation-list">
			<ContentSlider enableArrows={ true }>
				{ data.map( ( creation, i ) => (
					<React.Fragment key={ i }>
						<CreationListItem data={ creation } index={ i } total={ data.length } />
					</React.Fragment>
				) ) }
			</ContentSlider>
			<CDOLogo className="creation-list__background" />
		</div>
	)
}