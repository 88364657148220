import React from 'react'

export default function TestimonialCard ( props ) {

	const { data } = props

	return (
		<div className="testimonial-card">
			<blockquote className="testimonial-card__quote">
				{ data.quote }
			</blockquote>
			<span className="testimonial-card__author">
				{ data.author ? (
					<>
						<span>- { data.author }, </span><span className="testimonial-card__company">{ data.company }</span>
					</>
				) : (
					<span className="testimonial-card__company">- { data.company }</span>
				) }
				</span>
		</div>
	)
}