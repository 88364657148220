import React from 'react'
import { Link } from 'react-scroll'

import { MEASUREMENTS } from '../../helpers/constants'
import useWindowSize from '../../hooks/useWindowSize'

export default function CTABanner () {

	const { width } = useWindowSize()

	return (
		<section className="cta-banner">
			<div className="cta-banner__wrapper wrapper">
				<h2 className="cta-banner__heading">Start a new project with CDO?</h2>
				<Link
					to='contact-us'
					className="cta-banner__button"
					spy={ true }
					smooth={ true }
					offset={ width <= MEASUREMENTS.PHABLET ? -73 : -82 }
					duration={ 500 }>
					Contact us
				</Link>
			</div>
		</section>
	)
}