import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ClientsListItem from './ClientsListItem'

export default function ClientsList () {

	const queryData = useStaticQuery(graphql`
		query ClientsQuery {
			allClientsJson {
				nodes {
					id
					logo
					url
				}
			}
		}
	`)

	const data = queryData.allClientsJson.nodes

	return (
		<div className="clients__list">
			{ data.map( client => (
				<ClientsListItem data={ client } key={ client.id } />
			) ) }
		</div>
	)
}