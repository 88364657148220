import { useState, useEffect } from 'react'

export default function useWindowSize () {

	const RESIZE_DEBOUNCE = 150;

	const [ windowSize, setWindowSize ] = useState({
		width: typeof window !== 'undefined' ? window.innerWidth : 1200,
		height: typeof window !== 'undefined' ? window.innerHeight : 800
	})

	const getWindowSize = () => ( { width: window.innerWidth, height: window.innerHeight } )

	useEffect( () => {
		if ( typeof window !== 'undefined' ) {
			setWindowSize({ width: window.innerWidth, height: window.innerHeight })
		}

		// timeoutId for debounce mechanism
		let timeoutId = null
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWindowSize( getWindowSize() ), RESIZE_DEBOUNCE );
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};
	}, [] );

	return windowSize
}