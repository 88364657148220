import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ServiceCard from './ServiceCard'

export default function ServiceCardGrid () {

	const queryData = useStaticQuery(graphql`
		query ServiceCardsQuery {
			allServiceCardsJson {
				nodes {
					description
					icon
					heading
				}
			}
		}
	`)

	const data = queryData.allServiceCardsJson.nodes

	return (
		<div className="service-card-grid">
			{ data.map( ( card, index ) => (
				<ServiceCard data={ card } key={ index } />
			) ) }
		</div>
	)
}