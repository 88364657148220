import React from 'react'

import EcommerceIcon from '../../images/svgs/e-commerce-icon.svg'
import HostingIcon from '../../images/svgs/hosting-icon.svg'
import WebIcon from '../../images/svgs/web-icon.svg'

export default function ServiceCard ( props ) {

	const { data } = props

	const renderIcon = icon => {

		switch ( icon ) {
			case 'e-commerce':
				return <EcommerceIcon className="service-card__icon" />
			case 'hosting':
				return <HostingIcon className="service-card__icon" />
			case 'web':
				return <WebIcon className="service-card__icon" />
			default:
				return <WebIcon className="service-card__icon" />
		}
	}

	return (
		<div className="service-card">
			{ renderIcon( data.icon ) }
			<h3 className="service-card__heading">{ data.heading }</h3>
			<p className="service-card__description">{ data.description }</p>
		</div>
	)
}