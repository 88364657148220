import React, { useEffect, useState, useContext } from 'react'
import { scroller } from 'react-scroll'

import SEO from '../components/SEO/SEO'
import HomeHero from '../components/HomeHero/HomeHero'
import AboutUs from '../components/AboutUs/AboutUs'
import CTABanner from '../components/CTABanner/CTABanner'
import Services from '../components/Services/Services'
import Creations from '../components/Creations/Creations'
import Clients from '../components/Clients/Clients'
import Testimonials from '../components/Testimonials/Testimonials'
import SubFooter from '../components/SubFooter/SubFooter'
import ContactUs from '../components/ContactUs/ContactUs'

import { MEASUREMENTS } from '../helpers/constants'
import useWindowSize from '../hooks/useWindowSize'

import { AnimationContext } from '../context/AnimationContext'

export default function Home({location}) {

	const { animationEnabled, setAnimationEnabled, setIsHomepage } = useContext( AnimationContext )
	const [ animationComplete, setAnimationComplete ] = useState( !animationEnabled )
	const { width } = useWindowSize()

	// Sets isHomepage for animation, this is so the animation isn't triggered if they hit another page first
	useEffect(() => {
		setIsHomepage( true )
	}, [ setIsHomepage ] )

	useEffect(() => {

		if ( animationComplete && animationEnabled ) {
			scroller.scrollTo('about-us', {
				duration: 1500,
				delay: width > MEASUREMENTS.PHABLET ? 1000 : 0,
				smooth: 'easeInOutQuart',
				offset: -(window.innerHeight - ( window.innerHeight * .8 ))
			} )
			setAnimationEnabled( false )
		}

	}, [ animationComplete, width, setAnimationEnabled, animationEnabled ] )

	return (
		<>
			<SEO title="Home" pathname={ location.pathname } />
			<HomeHero animationComplete={ animationComplete } setAnimationComplete={ setAnimationComplete } />

			<AboutUs name="about-us" />
			<CTABanner />
			<Services />
			<Creations />
			<Clients />
			<Testimonials />
			<ContactUs />
			<SubFooter />
		</>
	)
}
